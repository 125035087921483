
































import { Component, Vue } from 'nuxt-property-decorator'
import EventsContainer from '@Events/components/containers/EventsContainer.vue'
import EventListGridView from '@Events/components/events/EventListGridView.vue'
import FeaturedEventCollection from '~/modules/@skyway/events/src/components/events/featured-events/FeaturedEventCollection.vue'
import EventCard from '@Events/components/events/EventCard.vue'

@Component({
  components: {
    EventsContainer,
    EventListGridView,
    FeaturedEventCollection,
    EventCard,
  },
})
export default class EventsPage extends Vue {
  head() {
    return {
      title: `What's on`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Browse our calendar, find out what's on and buy tickets for the latest live music gigs, theatre, circus, cabaret and spoken word events at the Roundhouse`,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: `What's On at Roundhouse`,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Browse our calendar, find out what's on and buy tickets for the latest live music gigs, theatre, circus, cabaret and spoken word events at the Roundhouse`,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: '/images/logos/RedLogo.png',
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: `https://www.roundhouse.org/whats-on`,
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: `website`,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: `What's On at Roundhouse`,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: `Browse our calendar, find out what's on and buy tickets for the latest live music gigs, theatre, circus, cabaret and spoken word events at the Roundhouse`,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: '/images/logos/RedLogo.png',
        },
        {
          hid: 'twitter:image:alt',
          name: 'twitter:image:alt',
          content: '/images/logos/RedLogo.png',
        },
      ],
    }
  }
  public currentMonth: string = this.$moment().format('YYYY-MM')
  public view = 'EventsGrid'
  public eventType = []

  handleMonthChange(val) {
    this.currentMonth = this.$moment(val).format('YYYY-MM')
  }

  changeView(val) {
    this.view = val
  }

  fetch() {
    const { query } = this.$route
    if (query.type) {
      this.eventType = [query.type]
    }
  }
}
