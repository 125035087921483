




















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Event } from '@Core/@types/skyway'
import { formatSummarisedDates } from '@Marketing/helpers/dates/index'

@Component
export default class FeaturedEventCard extends Vue {
  @Prop({ type: Object, required: true }) data!: Event

  get pageRedirectUrl(): string {
    const part =
      this.data.type === 'series'
        ? '/series/'
        : this.$config.get('URLS').whats_on
    return `${part}${this.data.alias}`
  }

  get date(): string | null {
    const { first_date, last_date, instance_count, type } = this.data

    return formatSummarisedDates(
      first_date,
      last_date,
      instance_count,
      this.$moment,
      type
    )
  }

  get image(): string | boolean {
    if (this.data.images && this.data.images.portrait) {
      return `${this.$config.get('ASSET_PATH')}/${this.data.images.portrait}`
    } else {
      return '/images/fallback.jpg'
    }
  }
}
