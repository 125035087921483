


































import { Component, Vue, namespace } from 'nuxt-property-decorator'
import FeaturedEventCard from '@Events/components/events/featured-events/FeaturedEventCard.vue'
import { Event } from '@Core/@types/skyway'

const events = namespace('events')

@Component({
  inheritAttrs: false,
  components: { FeaturedEventCard },
})
export default class FeaturedEventCollection extends Vue {
  @events.State
  protected events!: Event[]

  @events.Action
  protected getEvents

  @events.Getter
  protected featuredEvents!: (maxCount: number) => Event[] | []

  @events.Getter
  public upNextEvents!: Event[] | []

  get featuredEventCards(): Event[] | [] {
    // get 3 featured events
    const featuredEvents = this.featuredEvents(3)
    // find how many placeholder events are potentially needed
    const placeholdersNeeded = 3 - featuredEvents.length
    // slice the upNextEvents array to get the correct number of placeholder events
    const placeholderEvents = this.upNextEvents.slice(0, placeholdersNeeded)

    return [...featuredEvents, ...placeholderEvents]
  }

  getUrlPart(type: string) {
    return type === 'series' ? '/series/' : this.$config.get('URLS').whats_on
  }

  fetch() {
    if (!this.events || this.events.length === 0) {
      this.getEvents('getEventsShort')
    }
  }
}
